//import JScroll from '@twotwentytwo/jscroll'
import 'jscroll';


$( document ).ready(function() {
  if($(".image-results").length > 0){
    $('.image-results').jscroll({
        //loadingHtml: '<img src="loading.gif" alt="Loading" /> Loading...',
        loadingHtml: '<span>Loading...</span>',
        padding: 400,
        nextSelector: 'a.jscroll-next:first',
        //contentSelector: 'row'
    });
  }
})