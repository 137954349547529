import { Dropzone } from "dropzone";
//const dropzone = new Dropzone("div#myId", { url: "/file/post" });

$( document ).ready(function() {
  $(".multi-new-div").each(function(index){
    new Upload($(this));
  });
})

function Upload(dom){
 
  var dom = dom;
 
  init();
 
  function init(){
    
    
    dom.find(".uploadDropzone").each(function(_){
      
      var dropDom = $(this);
      var id = dropDom.attr("id");
      var dropzone = new Dropzone("#"+id, { 
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      });
    });
    
    
    dom.find(".gametype-btn").change(function(){
      
    
      var val = $(this).val();
      
      dom.find(".uploadDropzone").hide();
      dom.find(".uploadDropzone[data-gametype='"+val+"']").show();
    });
    
    
    
  }
}



